import {
  ChainParam,
  CurrencyParam,
  WalletFungiblePositionsResponse,
  WalletPortfolioResponse,
  WalletTransactionsResponse,
} from "@/types";
import { http } from "@/utils";

const getWalletPortfolio = async ({
  address,
  currency,
}: {
  address: string;
  currency?: CurrencyParam;
}) => {
  const response: WalletPortfolioResponse = await http.request({
    url: "wallets/portfolio/",
    method: "get",
    params: {
      address,
      currency,
    },
  });
  return response;
};

const getWalletTransactions = async ({
  address,
  currency,
  chains,
}: {
  address: string;
  currency?: CurrencyParam;
  chains?: ChainParam[];
}) => {
  const response: WalletTransactionsResponse = await http.request({
    url: `wallets/transactions/`,
    method: "get",
    params: {
      address,
      currency,
      chains: chains?.join(","),
    },
  });

  return response.filter(transaction => {
    return (
      transaction.attributes.sent_from.toLowerCase() ===
        address.toLowerCase() ||
      transaction.attributes.sent_to.toLowerCase() === address.toLowerCase()
    );
  });
};

const getWalletFungiblePositions = async ({
  address,
  fungibleId,
  currency,
  chain,
}: {
  address: string;
  fungibleId?: string;
  currency?: CurrencyParam;
  chain?: ChainParam;
}) => {
  const response: WalletFungiblePositionsResponse = await http.request({
    url: `wallets/fungible-positions/`,
    method: "get",
    params: {
      address,
      fungibleId,
      currency,
      chain,
    },
  });
  return response;
};

export {
  getWalletPortfolio,
  getWalletTransactions,
  getWalletFungiblePositions,
};

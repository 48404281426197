import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import i18n from "@/i18n";
import "./index.scss";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname.indexOf(path) >= 0;
  };

  return (
    <div className='app-sidebar'>
      <ul>
        <li
          className={isActive("/dashboard") ? "active" : ""}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          {i18n.t("layouts.sidebar.dashboard")}
        </li>
        <li
          className={isActive("/send") ? "active" : ""}
          onClick={() => {
            navigate("/send");
          }}
        >
          {i18n.t("layouts.sidebar.send")}
        </li>
        <li
          className={isActive("/settings") ? "active" : ""}
          onClick={() => {
            navigate("/settings");
          }}
        >
          {i18n.t("layouts.sidebar.settings")}
        </li>
      </ul>
    </div>
  );
};

export { Sidebar };

import React from "react";
import "./index.scss";

interface IProps {
  tabs: string[];
  onChange: (tab: string) => void;
}

const TabBar = ({ tabs, onChange }: IProps) => {
  const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

  const handleTabClick = (tab: string) => {
    console.log("tab:", tab);
    setActiveTab(tab);
    onChange(tab);
  };

  return (
    <div className='tab-container'>
      <div className='tab-buttons'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export { TabBar };

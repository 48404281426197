import React from "react";

import "./index.scss";
import { useStore } from "@/context";
import i18n from "@/i18n";
import { WalletTransactionsResponse } from "@/types";
import { abbreviateAddress } from "@/utils";

interface IProps {
  loading: boolean;
  data?: WalletTransactionsResponse;
}

const TransactionsList = ({ loading, data }: IProps) => {
  const { chainsList } = useStore();
  return (
    <div className='transactions-list'>
      <div className='list-item header'>
        <div>{i18n.t("components.transactions_list.activity")}</div>
        <div>{i18n.t("components.transactions_list.address")}</div>
        <div>{i18n.t("components.transactions_list.funds")}</div>
        <div>{i18n.t("components.transactions_list.tx_hash")}</div>
      </div>
      {loading ? (
        <div className='unlist-item'>{i18n.t("common.loading")}</div>
      ) : data?.length === 0 ? (
        <div className='unlist-item'>{i18n.t("common.no_items_found")}</div>
      ) : (
        data?.map((transactionInfo, index) => {
          const attributes = transactionInfo.attributes;
          const inInfo = attributes.transfers.filter(
            value => value.direction === "in",
          );
          const outInfo = attributes.transfers.filter(
            value => value.direction === "out",
          );
          const chainInfo = chainsList?.filter(
            chainInfo =>
              chainInfo.id === transactionInfo.relationships.chain.data.id,
          )[0];
          return (
            <div className='list-item' key={index}>
              <div>
                <div className='icon'>
                  <img
                    src={attributes.fee.fungible_info.icon.url || ""}
                    alt={attributes.fee.fungible_info.name}
                  />
                </div>
                <div className='text'>
                  <div className='title'>{attributes.operation_type}</div>
                  <div className='content'>{attributes.mined_at}</div>
                </div>
              </div>
              <div>
                <div className='text'>
                  <div>
                    <div>{i18n.t("components.transactions_list.from")}</div>
                    <div>{abbreviateAddress(attributes.sent_from)}</div>
                  </div>
                  <div>
                    <div>{i18n.t("components.transactions_list.to")}</div>
                    <div>{abbreviateAddress(attributes.sent_to)}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text'>
                  <div>
                    {inInfo.length > 0 && inInfo[0].fungible_info && (
                      <>
                        <div>{i18n.t("components.transactions_list.in")}</div>
                        <div>{`${inInfo[0].quantity.float.toFixed(4)}  ${
                          inInfo[0].fungible_info.symbol
                        }`}</div>
                      </>
                    )}
                  </div>
                  <div>
                    {outInfo.length > 0 && outInfo[0].fungible_info && (
                      <>
                        <div>{i18n.t("components.transactions_list.out")}</div>
                        <div>{`${outInfo[0].quantity.float.toFixed(4)}  ${
                          outInfo[0].fungible_info.symbol
                        }`}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <a
                  className='text'
                  href={chainInfo?.attributes.explorer.tx_url_format.replace(
                    "{HASH}",
                    attributes.hash,
                  )}
                  target='_blank'
                  rel='noreferrer'
                >
                  {abbreviateAddress(attributes.hash, 6)}
                </a>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export { TransactionsList };

import React from "react";

import { Navigate, useRoutes } from "react-router-dom";

import { AssetInfo, Dashboard, NotFound, Send, Settings } from "@/pages";

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to='/dashboard' />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/dashboard/asset-info/:fungibleId",
      element: <AssetInfo />,
    },
    {
      path: "/send",
      element: <Send />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};

export default Router;

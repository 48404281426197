import React from "react";

import { WalletPortfolioResponse } from "@/types";
import "./index.scss";

interface IProps {
  data?: WalletPortfolioResponse;
}

const Profile = ({ data }: IProps) => {
  const totalBalance = React.useMemo(
    () => data?.attributes.total.positions,
    [data],
  );
  const percent_1d = React.useMemo(
    () => data?.attributes.changes.percent_1d,
    [data],
  );
  const absolute_1d = React.useMemo(
    () => data?.attributes.changes.absolute_1d,
    [data],
  );
  return (
    <div className='profile'>
      <div className={data ? "image" : "image loading"}>
        {data ? (
          <img
            src={`https://mint.fun/api/avatar/${data.id}`}
            alt='profile-image'
          />
        ) : undefined}
      </div>
      <div className='info'>
        <div className='total-balance'>
          {`$${totalBalance?.toFixed(4) ?? "~"}`}
        </div>
        <div
          className={
            percent_1d
              ? percent_1d >= 0
                ? "floating-profit green"
                : "floating-profit red"
              : "floating-profit"
          }
        >
          {`${percent_1d?.toFixed(2) ?? "~"}%($${
            absolute_1d?.toFixed(2) ?? "~"
          })`}
        </div>
      </div>
    </div>
  );
};

export { Profile };

import React from "react";

import { ChainParam, ChainsListResponse } from "@/types";
import "./index.scss";

interface IProps {
  data?: ChainsListResponse;
  all?: boolean;
  defaultChain?: ChainParam;
  onSelect: (network: any) => void;
}

const NetworkSelector = ({
  data,
  defaultChain,
  all = true,
  onSelect,
}: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (all) {
      const value =
        e.target.value === "all" ? undefined : (e.target.value as ChainParam);
      onSelect(value);
    } else {
      onSelect(e.target.value as ChainParam);
    }
  };

  return (
    <div className='network-selector'>
      <select onChange={handleChange}>
        {all && <option value={undefined}>all</option>}
        {data?.map((chainInfo, index) => {
          return (
            <option
              key={index}
              value={chainInfo.id}
              selected={chainInfo.id === defaultChain}
            >
              {chainInfo.attributes.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export { NetworkSelector };

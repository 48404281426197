import React from "react";

import { useNavigate } from "react-router-dom";

import i18n from "@/i18n";
import { WalletFungiblePositionsResponse } from "@/types";

import "./index.scss";

interface IProps {
  loading: boolean;
  data?: WalletFungiblePositionsResponse;
}

const AssetsList = ({ data, loading }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className='assets-list'>
      <div className='list-item header'>
        <div>{i18n.t("components.assets_list.asset")}</div>
        <div>{i18n.t("components.assets_list.chain")}</div>
        <div>{i18n.t("components.assets_list.price")}</div>
        <div>{i18n.t("components.assets_list.balance")}</div>
        <div>{i18n.t("components.assets_list.value")}</div>
      </div>
      {loading ? (
        <div className='unlist-item'>{i18n.t("common.loading")}</div>
      ) : data?.length === 0 ? (
        <div className='unlist-item'>{i18n.t("common.no_items_found")}</div>
      ) : (
        data?.map((assetInfo, index) => {
          const { attributes, relationships } = assetInfo;
          return (
            <div
              className='list-item content'
              key={index}
              onClick={() =>
                navigate(
                  `/dashboard/asset-info/${relationships.fungible.data.id}`,
                )
              }
            >
              <div>
                <div className='token-image'>
                  <img
                    src={attributes.fungible_info.icon.url || ""}
                    alt={attributes.fungible_info.name || "unknown"}
                  />
                </div>
                <div className='token-info'>
                  <div className='symbol'>
                    {attributes.fungible_info.symbol}
                  </div>
                  <div className='name'>{attributes.fungible_info.name}</div>
                </div>
              </div>
              <div>
                {assetInfo.id.substring(
                  assetInfo.id.indexOf("-") + 1,
                  assetInfo.id.indexOf("-asset"),
                )}
              </div>
              <div>{`$ ${attributes.price.toFixed(2)}`}</div>
              <div>{`${attributes.quantity.float.toFixed(4)} ${
                attributes.fungible_info.symbol
              }`}</div>
              <div className='column'>
                <div className='value'>{`$ ${(
                  attributes.quantity.float * attributes.price
                ).toFixed(4)} `}</div>
                <div
                  className={
                    attributes.changes.percent_1d >= 0
                      ? "change green"
                      : "change red"
                  }
                >{`${
                  attributes.changes.percent_1d >= 0 ? "+" : ""
                }${attributes.changes.percent_1d.toFixed(2)}%(${
                  attributes.changes.percent_1d >= 0 ? "+" : ""
                }${attributes.changes.absolute_1d.toFixed(4)}$)`}</div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export { AssetsList };

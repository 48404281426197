import React from "react";
import "./index.scss";

import {
  TabBar,
  AssetsList,
  NetworkSelector,
  Profile,
  TransactionsList,
} from "@/components";
import { ADDRESS_UNDEFIEND, NETWORK_UNDEFIEND } from "@/constants";
import { useStore } from "@/context";
import i18n from "@/i18n";
import {
  getChain,
  getWalletFungiblePositions,
  getWalletPortfolio,
  getWalletTransactions,
} from "@/services";
import { ChainParam } from "@/types";

const Dashboard = () => {
  const {
    address,
    chainsList,
    walletPortfolio,
    setWalletPortfolio,
    walletTransactions,
    setWalletTransactions,
    walletFungiblePositions,
    setWalletFungiblePositions,
  } = useStore();

  const [network, setNetwork] = React.useState<ChainParam>();
  const [activeTab, setActiveTab] = React.useState<string>(
    i18n.t("dashboard.asset"),
  );
  const [isGettingWalletTransactions, setIsGettingWalletTransactions] =
    React.useState<boolean>(false);
  const [
    isGettingWalletFungiblePositions,
    setIsGettingWalletFungiblePositions,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (address) {
      initWalletPortfolio();
      initFungiblePositions();
      initWalletTransactions();
    }
  }, [address]);

  React.useEffect(() => {
    if (network) {
      initChain();
    }
    if (address) {
      initFungiblePositions();
      initWalletTransactions();
    }
  }, [network]);

  const initWalletPortfolio = async () => {
    if (!address) {
      throw ADDRESS_UNDEFIEND;
    }
    const portfolio = await getWalletPortfolio({
      address,
    });
    setWalletPortfolio(portfolio);
    console.log("portfolio:", portfolio);
  };

  const initWalletTransactions = async () => {
    if (!address) {
      throw ADDRESS_UNDEFIEND;
    }
    console.log("walletTransactions:", walletTransactions);
    if (!walletTransactions) {
      setIsGettingWalletTransactions(true);
    }
    try {
      const transactions = await getWalletTransactions({
        address,
        chains: network && [network],
      });
      console.log("transactions:", transactions);
      setWalletTransactions(transactions);
    } catch (e) {
      console.error(e);
    } finally {
      if (!walletTransactions) {
        setIsGettingWalletTransactions(false);
      }
    }
  };

  const initChain = async () => {
    if (!network) {
      throw NETWORK_UNDEFIEND;
    }
    const chain = await getChain(network);
    console.log("chain:", chain);
  };

  const initFungiblePositions = async () => {
    if (!address) {
      throw ADDRESS_UNDEFIEND;
    }
    if (!walletFungiblePositions) {
      setIsGettingWalletFungiblePositions(true);
    }
    try {
      const fungbilePostions = await getWalletFungiblePositions({
        address,
        chain: network,
      });
      setWalletFungiblePositions(fungbilePostions);
      console.log("fungbilePostions:", fungbilePostions);
    } catch (e) {
      console.error(e);
    } finally {
      if (!walletFungiblePositions) {
        setIsGettingWalletFungiblePositions(false);
      }
    }
  };

  return (
    <div id='dashboard'>
      <div className='dashboard-header'>
        <Profile data={walletPortfolio} />
        <NetworkSelector
          data={chainsList}
          onSelect={(network?: ChainParam) => setNetwork(network)}
        />
      </div>
      <div className='gap' />
      <TabBar
        tabs={[i18n.t("dashboard.asset"), i18n.t("dashboard.transactions")]}
        onChange={tab => {
          setActiveTab(tab);
        }}
      />
      <div className='dashboard-body'>
        {activeTab === i18n.t("dashboard.asset") ? (
          <AssetsList
            loading={isGettingWalletFungiblePositions}
            data={walletFungiblePositions}
          />
        ) : activeTab === i18n.t("dashboard.transactions") ? (
          <TransactionsList
            loading={isGettingWalletTransactions}
            data={walletTransactions}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export { Dashboard };

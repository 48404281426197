import React from "react";

import { ChainParam, WalletFungiblePositionsResponse } from "@/types";
import "./index.scss";

interface IProps {
  data?: WalletFungiblePositionsResponse;
  onSelect: (token: string) => void;
}

const TokenSelector = ({ data, onSelect }: IProps) => {
  const [currentNetwork, setCurrentNetwork] = React.useState<ChainParam>();

  React.useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      data[0].relationships.chain.data.id !== currentNetwork
    ) {
      setCurrentNetwork(data[0].relationships.chain.data.id as ChainParam);
      onSelect(data[0].attributes.fungible_info.symbol);
    }
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(e.target.value);
  };

  return (
    <div className='token-selector'>
      <select onChange={handleChange}>
        {data?.map((fungiblePosition, index) => {
          const fungibleInfo = fungiblePosition.attributes.fungible_info;
          return (
            <option key={index} value={fungibleInfo.symbol}>
              {/* <img src={fungibleInfo.icon.url} alt={fungibleInfo.name} />
              <span>{fungibleInfo.symbol}</span> */}
              {fungibleInfo.symbol}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export { TokenSelector };

import React from "react";

import { ethers } from "ethers";

import { PortfolioContext } from "./provider";

import {
  ActionType,
  ChainsListResponse,
  WalletFungiblePositionsResponse,
  WalletPortfolioResponse,
  WalletTransactionsResponse,
} from "@/types";

const useStore = () => {
  const context = React.useContext(PortfolioContext);
  if (context === undefined) {
    throw "PortfolioContext undefined";
  }

  const { state, dispatch } = context;

  const isPending = React.useMemo(() => {
    return state.pendingCount > 0;
  }, [state.pendingCount]);

  const walletSigner = React.useMemo(() => {
    if (state.address) {
      const web3Provider = new ethers.providers.Web3Provider(
        state.walletProvider,
        "any",
      );
      return web3Provider.getSigner();
    }
  }, [state.walletProvider, state.address]);

  const setAddress = (address: string) => {
    dispatch({
      type: ActionType.SetAddress,
      payload: address,
    });
  };

  const setIsPending = (value: boolean) => {
    if (value) {
      dispatch({
        type: ActionType.IncreasePendingCount,
      });
    } else {
      dispatch({
        type: ActionType.DecreasePendingCount,
      });
    }
  };

  const setChainsList = (chainsList: ChainsListResponse) => {
    dispatch({
      type: ActionType.SetChainsList,
      payload: chainsList,
    });
  };

  const setWalletPortfolio = (walletPortfolio: WalletPortfolioResponse) => {
    dispatch({
      type: ActionType.SetWalletPortfolio,
      payload: walletPortfolio,
    });
  };

  const setWalletTransactions = (
    walletTransactions: WalletTransactionsResponse,
  ) => {
    dispatch({
      type: ActionType.SetWalletTransactions,
      payload: walletTransactions,
    });
  };

  const setWalletFungiblePositions = (
    walletFungiblePositions: WalletFungiblePositionsResponse,
  ) => {
    dispatch({
      type: ActionType.SetWalletFungiblePositions,
      payload: walletFungiblePositions,
    });
  };

  return {
    ...state,
    walletSigner,
    isPending,
    setAddress,
    setIsPending,
    setChainsList,
    setWalletPortfolio,
    setWalletTransactions,
    setWalletFungiblePositions,
  };
};

export { useStore };

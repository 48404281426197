import React from "react";

import { useParams } from "react-router-dom";

import { Explorer, LineChart, LoadingSkeleton } from "@/components";
import { useStore } from "@/context";
import i18n from "@/i18n";
import {
  getAssetById,
  getAssetsChart,
  getWalletFungiblePositions,
} from "@/services";
import {
  AssetByIdResponse,
  AssetsChartResponse,
  ChainParam,
  PeriodParam,
} from "@/types";
import "./index.scss";

const AssetInfo = () => {
  const { address, chainsList } = useStore();
  const { fungibleId } = useParams();
  const [chartPeriod, setChartPeriod] = React.useState<PeriodParam>(
    PeriodParam.Day,
  );
  const [assetInfo, setAssetInfo] = React.useState<AssetByIdResponse>();
  const [assetsChartList, setAssetsChartList] =
    React.useState<AssetsChartResponse[]>();
  React.useEffect(() => {
    initAsset();
  }, []);

  React.useEffect(() => {
    initAssetsChart();
  }, [chartPeriod]);

  React.useEffect(() => {
    initFungiblePositions();
  }, [address]);

  const chartPeriods = React.useMemo(
    () => [
      PeriodParam.Day,
      PeriodParam.Week,
      PeriodParam.Month,
      PeriodParam.Year,
      PeriodParam.Max,
    ],
    [],
  );

  const currentAssetsChart = React.useMemo(() => {
    if (assetsChartList && chartPeriod) {
      return assetsChartList[chartPeriods.indexOf(chartPeriod)];
    } else {
      return;
    }
  }, [assetsChartList, chartPeriod]);

  const initAsset = async () => {
    const assetInfo = await getAssetById({ fungibleId });
    console.log("assetInfo:", assetInfo);
    setAssetInfo(assetInfo);
  };

  const initAssetsChart = async () => {
    const assetsChartList = await Promise.all(
      chartPeriods.map(chartPeriod =>
        getAssetsChart({
          fungibleId,
          chartPeriod,
        }),
      ),
    );
    setAssetsChartList(assetsChartList);
  };

  const initFungiblePositions = async () => {
    if (!address) {
      return;
    }
    const fungbilePostions = await getWalletFungiblePositions({
      address,
      fungibleId,
    });
    console.log("fungbilePostions:", fungbilePostions);
  };

  const getExplorerData = (chain: ChainParam) => {
    return chainsList?.filter(chainInfo => {
      return chainInfo.id === chain;
    })[0];
  };

  return (
    <div id='asset-info'>
      <div className='asset-info-header'>
        {assetInfo ? (
          <>
            <img
              src={assetInfo?.attributes.icon.url}
              alt={assetInfo?.attributes.name}
            />
            <div className='text'>{assetInfo?.attributes.name}</div>
          </>
        ) : (
          <div className='skeleton'>
            <LoadingSkeleton className='skeleton__image' />
            <LoadingSkeleton className='skeleton__text' />
          </div>
        )}
      </div>
      <div className='asset-info-body'>
        <LineChart uniqueId={fungibleId} data={currentAssetsChart} />
        <div className='duration-operation'>
          {chartPeriods.map((periodValue, index) => {
            return (
              <button
                key={index}
                className={chartPeriod === periodValue ? "selected" : ""}
                onClick={() => setChartPeriod(periodValue)}
              >
                {periodValue}
              </button>
            );
          })}
        </div>
        <div className='stats'>
          <h2>{i18n.t("dashboard.asset_info.stats")}</h2>
          <div className='stats-content'>
            <div className='stat'>
              <div className='title'>{i18n.t("dashboard.asset_info.1day")}</div>
              <div
                className={
                  assetInfo?.attributes.market_data.changes.percent_1d
                    ? assetInfo?.attributes.market_data.changes.percent_1d >= 0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`${
                  assetInfo?.attributes.market_data.changes.percent_1d.toFixed(
                    2,
                  ) || "~"
                } %`}
              </div>
            </div>
            <div className='stat'>
              <div className='title'>
                {i18n.t("dashboard.asset_info.30day")}
              </div>
              <div
                className={
                  assetInfo?.attributes.market_data.changes.percent_30d
                    ? assetInfo?.attributes.market_data.changes.percent_30d >= 0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`${
                  assetInfo?.attributes.market_data.changes.percent_30d.toFixed(
                    2,
                  ) || "~"
                } %`}
              </div>
            </div>
            <div className='stat'>
              <div className='title'>
                {i18n.t("dashboard.asset_info.90day")}
              </div>
              <div
                className={
                  assetInfo?.attributes.market_data.changes.percent_90d
                    ? assetInfo?.attributes.market_data.changes.percent_90d >= 0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`${
                  assetInfo?.attributes.market_data.changes.percent_90d.toFixed(
                    2,
                  ) || "~"
                } %`}
              </div>
            </div>
            <div className='stat'>
              <div className='title'>
                {i18n.t("dashboard.asset_info.365day")}
              </div>
              <div
                className={
                  assetInfo?.attributes.market_data.changes.percent_365d
                    ? assetInfo?.attributes.market_data.changes.percent_365d >=
                      0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`${
                  assetInfo?.attributes.market_data.changes.percent_365d.toFixed(
                    2,
                  ) || "~"
                } %`}
              </div>
            </div>
            <div className='stat'>
              <div className='title'>
                {i18n.t("dashboard.asset_info.market_cap")}
              </div>
              <div
                className={
                  assetInfo?.attributes.market_data.market_cap
                    ? assetInfo?.attributes.market_data.market_cap >= 0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`$ ${assetInfo?.attributes.market_data.market_cap || "~"}`}
              </div>
            </div>
            <div className='stat'>
              <div className='title'>
                {i18n.t("dashboard.asset_info.fully_diluted")}
              </div>
              <div
                className={
                  assetInfo?.attributes.market_data.fully_diluted_valuation
                    ? assetInfo?.attributes.market_data
                        .fully_diluted_valuation >= 0
                      ? "green"
                      : "red"
                    : ""
                }
              >
                {`$ ${
                  assetInfo?.attributes.market_data.fully_diluted_valuation ||
                  "~"
                }`}
              </div>
            </div>
          </div>
        </div>
        <div className='explorers'>
          <h2>{i18n.t("dashboard.asset_info.explorers")}</h2>
          <div className='explorers-display'>
            {assetInfo?.attributes.implementations.map((chainInfo, index) => {
              return (
                getExplorerData(chainInfo.chain_id as ChainParam) && (
                  <Explorer
                    key={index}
                    token={chainInfo.address}
                    data={getExplorerData(chainInfo.chain_id as ChainParam)}
                  />
                )
              );
            })}
          </div>
        </div>
        <div className='about'>
          <h2>{`${i18n.t("dashboard.asset_info.about")} ${
            assetInfo?.attributes.name || ""
          }`}</h2>
          <div>{assetInfo?.attributes.description}</div>
          <div className='social-media'>
            {assetInfo?.attributes.external_links.map((value, index) => (
              <span key={index}>
                ☞
                <a href={value.url} target='_blank' rel='noreferrer'>
                  {`${value.name}`}
                </a>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AssetInfo };

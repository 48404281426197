import React from "react";

import { initialState, reducer } from "./state";

import { ContextType } from "@/types";

const PortfolioContext = React.createContext<ContextType>({} as ContextType);

const PortfolioContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <PortfolioContext.Provider value={{ state, dispatch }}>
      {children}
    </PortfolioContext.Provider>
  );
};

export { PortfolioContext, PortfolioContextProvider };

import React from "react";

import i18n from "@/i18n";
import { GasInfo } from "@/types";
import "./index.scss";

interface IProps {
  data: GasInfo;
}

const GasPrice = ({ data }: IProps) => {
  return (
    <div className='gas-price'>
      <div>
        <div className='key'>{i18n.t("components.gas_price.fast")}</div>
        <div className='value'>{`${data.fast} wei`}</div>
      </div>
      <div>
        <div className='key'>{i18n.t("components.gas_price.standard")}</div>
        <div className='value'>{`${data.standard} wei`}</div>
      </div>
      <div>
        <div className='key'>{i18n.t("components.gas_price.slow")}</div>
        <div className='value'>{`${data.slow} wei`}</div>
      </div>
    </div>
  );
};

export { GasPrice };

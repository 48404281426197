const ADDRESS_UNDEFIEND = new Error("address undefined");
const NETWORK_UNDEFIEND = new Error("network undefined");
const CHAINS_LIST_UNDEFINED = new Error("chainsList undefined");
const TOKEN_INFO_FILTER_FAILED = new Error("token info filter failed");

export {
  ADDRESS_UNDEFIEND,
  NETWORK_UNDEFIEND,
  CHAINS_LIST_UNDEFINED,
  TOKEN_INFO_FILTER_FAILED,
};

import React from "react";
import "./index.scss";

const Settings = () => {
  return (
    <div id='settings'>
      <h1>Settings</h1>
    </div>
  );
};

export { Settings };

import React from "react";
import "./App.scss";

import { Header, Sidebar } from "./layouts";
import Router from "./router";

const App = () => {
  return (
    <div id='app'>
      <Header />
      <div className='container'>
        <Sidebar />
        <div className='app-content'>
          <Router />
        </div>
      </div>
    </div>
  );
};

export default App;

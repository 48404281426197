import { ChainParam, GasPricesResponse } from "@/types";
import { http } from "@/utils";

const getGasPrices = async (chain: ChainParam) => {
  const response: GasPricesResponse = await http.request({
    url: "gas-prices/",
    method: "get",
    params: {
      chain,
    },
  });
  return response;
};

export { getGasPrices };

import React from "react";

import "./index.scss";
import { detectDataverseExtension } from "@dataverse/utils";
import { useNavigate } from "react-router-dom";

import Logo from "@/assets/portfolio.svg";
import { LoadingSpinner } from "@/components";
import { useStore } from "@/context";
import i18n from "@/i18n";
import { getChainsList } from "@/services";
import { abbreviateAddress, copyToClipboard, Notification } from "@/utils";

const Header = () => {
  const navigate = useNavigate();
  const { walletProvider, address, setAddress, isPending, setChainsList } =
    useStore();

  const [hasDataverseExtension, setHasDataverseExtension] =
    React.useState<boolean>();

  React.useEffect(() => {
    (async () => {
      const hasDataverseExtension = await detectDataverseExtension();
      setHasDataverseExtension(hasDataverseExtension);
    })();
    initChainsList();
  }, []);

  React.useEffect(() => {
    if (typeof hasDataverseExtension === "undefined") {
      return;
    }
    (async () => {
      if (hasDataverseExtension) {
        try {
          const res = await walletProvider.getCurrentWallet();
          if (res && res.address && res.wallet) {
            setAddress(res.address);
            walletProvider.connectWallet(res.wallet);
          }
        } catch (e: unknown) {
          Notification.showErrorMessage(
            (e as Error).message ? (e as Error).message : JSON.stringify(e),
          );
          throw e;
        }
      } else {
        Notification.showHintMessage(i18n.t("layouts.header.hint_message"));
      }
    })();
  }, [hasDataverseExtension]);

  const initChainsList = async () => {
    const chainsList = await getChainsList();
    console.log("chainsList:", chainsList);
    setChainsList(chainsList);
  };

  const handleConnectWallet = async () => {
    if (hasDataverseExtension === false) {
      window.open(process.env.DATAVERSE_GOOGLE_STORE);
      return;
    }

    if (address) {
      copyToClipboard(address);
      Notification.showSuccessMessage(
        i18n.t("layouts.header.copy_message", {
          address: abbreviateAddress(address),
        }),
      );
      return;
    }

    try {
      const { address: _address } = await walletProvider.connectWallet();
      setAddress(_address);
    } catch (e: unknown) {
      Notification.showErrorMessage(
        (e as Error).message ? (e as Error).message : JSON.stringify(e),
      );
      throw e;
    }
  };

  return (
    <div className='app-header'>
      <div
        className='logo-area'
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} alt='dataverse-logo' />
        <h1>{i18n.t("common.portfolio")}</h1>
      </div>
      <div
        className={
          !hasDataverseExtension
            ? "connect-wallet no-extension"
            : isPending
            ? "connect-wallet pending"
            : "connect-wallet"
        }
        onClick={handleConnectWallet}
      >
        {typeof hasDataverseExtension === "undefined" ? (
          <LoadingSpinner />
        ) : hasDataverseExtension ? (
          address ? (
            isPending ? (
              <>
                <LoadingSpinner />
                &nbsp;
                <span>{i18n.t("layouts.header.pending")}</span>
              </>
            ) : (
              <span className='span__address'>
                {abbreviateAddress(address)}
              </span>
            )
          ) : (
            <span>{i18n.t("layouts.header.connect_wallet")}</span>
          )
        ) : (
          <span>{i18n.t("layouts.header.install_dataverse")}</span>
        )}
      </div>
    </div>
  );
};

export { Header };

import React from "react";

import { useNavigate } from "react-router-dom";

import i18n from "@/i18n";
import "./index.scss";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div id='not-found'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 '>
            <div className='col-sm-10 col-sm-offset-1  text-center'>
              <div className='four_zero_four_bg'>
                <h1 className='text-center '>{i18n.t("not_found.404")}</h1>
              </div>
              <div className='contant_box_404'>
                <h3 className='h2'>{i18n.t("not_found.title")}</h3>

                <p>{i18n.t("not_found.content")}</p>

                <div
                  className='link_404'
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  {i18n.t("not_found.redirect")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NotFound };

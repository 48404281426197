import Notify, { API, CustomNotificationObject } from "bnc-notify";

class Notification {
  static notify: API;

  static register() {
    this.notify = Notify({
      dappId: process.env.BLOCK_NATIVE_APP_ID,
      networkId: 1,
    });

    this.notify.config({
      desktopPosition: "bottomRight",
      mobilePosition: "top",
    });
  }

  static track(networkId: number, hash: string) {
    this.notify.config({
      networkId,
    });
    this.notify.hash(hash);
  }

  static showErrorMessage(message: string, autoDismiss: number = 10000) {
    const notificationObject = {
      type: "error",
      autoDismiss,
      message,
    } as CustomNotificationObject;

    this.notify.notification(notificationObject);
  }

  static showSuccessMessage(message: string, autoDismiss: number = 5000) {
    const notificationObject = {
      type: "success",
      autoDismiss,
      message,
    } as CustomNotificationObject;

    this.notify.notification(notificationObject);
  }

  static showHintMessage(message: string, autoDismiss: number = 1000000) {
    const notificationObject = {
      type: "hint",
      autoDismiss,
      message,
    } as CustomNotificationObject;

    this.notify.notification(notificationObject);
  }
}

Notification.register();

export { Notification };

import { WalletProvider } from "@dataverse/wallet-provider";

import { ActionType, StateType } from "@/types";

const initialState = {
  walletProvider: new WalletProvider(),
  pendingCount: 0,
  address: undefined,
  chainsList: undefined,
  walletPortfolio: undefined,
  walletTransactions: undefined,
  walletFungiblePositions: undefined,
};

const reducer = (
  state: StateType,
  action: {
    type: ActionType;
    payload: any;
  },
): StateType => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetAddress: {
      return {
        ...state,
        address: payload,
      };
    }

    case ActionType.IncreasePendingCount: {
      return {
        ...state,
        pendingCount: state.pendingCount + 1,
      };
    }

    case ActionType.DecreasePendingCount: {
      const value = state.pendingCount - 1 >= 0 ? state.pendingCount - 1 : 0;
      return {
        ...state,
        pendingCount: value,
      };
    }

    case ActionType.SetChainsList: {
      return {
        ...state,
        chainsList: payload,
      };
    }

    case ActionType.SetWalletPortfolio: {
      return {
        ...state,
        walletPortfolio: payload,
      };
    }

    case ActionType.SetWalletTransactions: {
      return {
        ...state,
        walletTransactions: payload,
      };
    }

    case ActionType.SetWalletFungiblePositions: {
      return {
        ...state,
        walletFungiblePositions: payload,
      };
    }
  }
};

export { initialState, reducer };

import React from "react";
import "./index.scss";

import { ethers } from "ethers";

import {
  GasPrice,
  LoadingSpinner,
  NetworkSelector,
  TokenSelector,
} from "@/components";
import {
  ADDRESS_UNDEFIEND,
  CHAINS_LIST_UNDEFINED,
  NATIVE_TOKEN,
  POLYGON_NATIVE_TOKEN,
  NETWORK_UNDEFIEND,
  TOKEN_INFO_FILTER_FAILED,
} from "@/constants";
import { useStore } from "@/context";
import ERC20ABI from "@/contracts/ERC20.json";
import i18n from "@/i18n";
import {
  getAssetById,
  getGasPrices,
  getWalletFungiblePositions,
} from "@/services";
import {
  ChainParam,
  GasInfo,
  GasPriceResponse,
  GasPricesResponse,
  WalletFungiblePositionsResponse,
} from "@/types";
import { Notification } from "@/utils";

const Send = () => {
  const { address, chainsList, walletProvider, walletSigner, setIsPending } =
    useStore();
  const [network, setNetwork] = React.useState<ChainParam>(ChainParam.Polygon);
  const [gasPrices, setGasPrices] = React.useState<GasPricesResponse>();
  const [recipient, setRecipient] = React.useState<string>();
  const [assetNum, setAssetNum] = React.useState<string>();
  const [currentTokenSymbol, setCurrentTokenSymbol] = React.useState<string>();
  const [walletFungiblePositions, setWalletFungiblePositions] =
    React.useState<WalletFungiblePositionsResponse>();
  const [isSendButtonLoading, setIsSendButtonLoading] =
    React.useState<boolean>(false);
  const isSendButtonDisabled = React.useMemo(() => {
    return !recipient || !assetNum || isSendButtonLoading;
  }, [recipient, assetNum, isSendButtonLoading]);

  const currentNetworkId = React.useMemo(() => {
    const networkIdStr = chainsList?.filter(chain => chain.id === network)[0]
      .attributes.external_id;
    if (networkIdStr) {
      return parseInt(networkIdStr, 16);
    } else {
      return;
    }
  }, [network, chainsList]);

  React.useEffect(() => {
    if (network) {
      initGasPrice();
    }
    if (address) {
      initFungiblePositions();
    }
  }, [network, address]);

  const balance = React.useMemo(() => {
    return (
      walletFungiblePositions?.filter(
        position =>
          position.attributes.fungible_info.symbol === currentTokenSymbol,
      )[0]?.attributes.quantity.float || ""
    );
  }, [currentTokenSymbol, walletFungiblePositions]);

  const tokenInfo = React.useMemo(() => {
    const tokenImplementations = walletFungiblePositions?.filter(
      position =>
        position.attributes.fungible_info.symbol === currentTokenSymbol,
    )[0]?.attributes.fungible_info.implementations;

    const _tokenInfo = tokenImplementations?.filter(
      impl => impl.chain_id === network,
    )[0];

    if (_tokenInfo?.address === null) {
      _tokenInfo.address = NATIVE_TOKEN;
    }
    return _tokenInfo;
  }, [currentTokenSymbol]);

  const gasInfo: GasInfo | undefined = React.useMemo(() => {
    if (gasPrices) {
      let targetGasPrice: GasPriceResponse | undefined;
      if (gasPrices.length === 1) {
        targetGasPrice = gasPrices[0];
      } else if (gasPrices.length > 1) {
        targetGasPrice = gasPrices.filter(
          value => value.attributes.gas_type === "eip1559",
        )[0];
      } else {
        targetGasPrice = undefined;
      }

      if (
        targetGasPrice?.attributes.gas_type === "eip1559" &&
        typeof targetGasPrice?.attributes.info.slow !== "number" &&
        typeof targetGasPrice?.attributes.info.fast !== "number" &&
        typeof targetGasPrice?.attributes.info.standard !== "number"
      ) {
        return {
          slow: targetGasPrice.attributes.info.slow.priority_fee,
          fast: targetGasPrice.attributes.info.fast.priority_fee,
          standard: targetGasPrice.attributes.info.standard.priority_fee,
        };
      }
      if (
        targetGasPrice?.attributes.gas_type === "classic" &&
        typeof targetGasPrice?.attributes.info.slow === "number" &&
        typeof targetGasPrice?.attributes.info.fast === "number" &&
        typeof targetGasPrice?.attributes.info.standard === "number"
      ) {
        return {
          slow: targetGasPrice?.attributes.info.slow,
          fast: targetGasPrice?.attributes.info.fast,
          standard: targetGasPrice?.attributes.info.standard,
        };
      }
    }
    return {
      slow: 0,
      fast: 0,
      standard: 0,
    };
  }, [gasPrices]);

  const switchNetwork = async () => {
    if (!chainsList) {
      throw CHAINS_LIST_UNDEFINED;
    }

    const chain = chainsList.filter(chainInfo => chainInfo.id === network)[0];
    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.attributes.external_id }],
      });
    } catch (error: any) {
      if (error.indexOf("Unrecognized chain ID") >= 0) {
        const nativeTokenInfo = await getAssetById({
          fungibleId: chain.relationships.native_fungible.data.id,
        });
        await walletProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chain.attributes.external_id,
              rpcUrls: chain.attributes.rpc.public_servers_url,
              chainName: chain.attributes.name,
              nativeCurrency: {
                name: nativeTokenInfo.attributes.name,
                symbol: nativeTokenInfo.attributes.symbol,
                decimals: 18,
              },
              blockExplorerUrls: [chain.attributes.explorer.home_url],
            },
          ],
        });
      }
    }
  };

  const initFungiblePositions = async () => {
    if (!address) {
      throw ADDRESS_UNDEFIEND;
    }
    try {
      const fungbilePostions = await getWalletFungiblePositions({
        address,
        chain: network,
      });
      setWalletFungiblePositions(fungbilePostions);
    } catch (e) {
      console.error(e);
    }
  };

  const initGasPrice = async () => {
    if (!network) {
      throw NETWORK_UNDEFIEND;
    }
    const gasPrices = await getGasPrices(network);
    setGasPrices(gasPrices);
  };

  const handleSend = async () => {
    if (!tokenInfo?.address) {
      throw TOKEN_INFO_FILTER_FAILED;
    }

    await switchNetwork();

    if (
      tokenInfo?.address === NATIVE_TOKEN ||
      tokenInfo?.address === POLYGON_NATIVE_TOKEN
    ) {
      const rawTx = {
        to: recipient,
        value: ethers.utils.parseEther(assetNum),
      };

      let tx;
      try {
        tx = await walletSigner.sendTransaction(rawTx);
      } catch (e: unknown) {
        Notification.showErrorMessage(
          (e as Error).message ? (e as Error).message : JSON.stringify(e),
        );
        throw e;
      }

      setIsPending(true);
      setIsSendButtonLoading(true);

      try {
        Notification.track(currentNetworkId!, tx.hash);
        await tx.wait();
      } catch (e: unknown) {
        Notification.showErrorMessage(
          (e as Error).message ? (e as Error).message : JSON.stringify(e),
        );
        throw e;
      } finally {
        setIsPending(false);
        setIsSendButtonLoading(false);
      }
    } else {
      const erc20Contract = new ethers.Contract(
        tokenInfo.address,
        ERC20ABI,
        walletSigner,
      );

      let tx;
      try {
        tx = await erc20Contract.transfer(
          recipient,
          ethers.utils.parseUnits(assetNum, tokenInfo.decimals),
        );
      } catch (e: unknown) {
        Notification.showErrorMessage(
          (e as Error).message ? (e as Error).message : JSON.stringify(e),
        );
        throw e;
      }

      setIsPending(true);
      setIsSendButtonLoading(true);

      try {
        Notification.track(currentNetworkId!, tx.hash);
        await tx.wait();
      } catch (e: unknown) {
        Notification.showErrorMessage(
          (e as Error).message ? (e as Error).message : JSON.stringify(e),
        );
        throw e;
      } finally {
        setIsPending(false);
        setIsSendButtonLoading(false);
      }
    }
    initFungiblePositions();
  };

  return (
    <div id='send'>
      <div className='send-header'>
        <GasPrice data={gasInfo} />
        <NetworkSelector
          data={chainsList}
          all={false}
          onSelect={(network: ChainParam) => setNetwork(network)}
          defaultChain={ChainParam.Polygon}
        />
      </div>
      <div className='gap' />
      <div className='send-body'>
        <div className='send-panel'>
          <div className='input-area'>
            <div className='top-text'>{i18n.t("send.recipient")}</div>
            <input
              value={recipient || ""}
              onChange={e => setRecipient(e.target.value)}
            />
          </div>
          <div className='gap' />
          <div className='input-area'>
            <div className='top-text'>{i18n.t("send.asset")}</div>
            <input
              className='short'
              value={assetNum || ""}
              onChange={e => setAssetNum(e.target.value)}
            ></input>
            <TokenSelector
              data={walletFungiblePositions}
              onSelect={(currentTokenSymbol: string) =>
                setCurrentTokenSymbol(currentTokenSymbol)
              }
            />
            <div className='bottom-text'>{`${i18n.t(
              "send.balance",
            )}: ${balance}`}</div>
          </div>
          <div className='gap' />
          <button
            className={isSendButtonDisabled ? "disabled" : ""}
            disabled={isSendButtonDisabled}
            onClick={handleSend}
          >
            {isSendButtonLoading ? (
              <LoadingSpinner />
            ) : (
              <span>{i18n.t("send.send")}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export { Send };

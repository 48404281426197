import React from "react";

import * as echarts from "echarts";

// import dataSetJson from "./a.json";
import "./index.scss";
import { AssetsChartResponse } from "@/types";
import { timestampToTime } from "@/utils";

interface IProps {
  uniqueId: string;
  data?: AssetsChartResponse;
}

const LineChart = ({ uniqueId, data }: IProps) => {
  React.useEffect(() => {
    if (data) {
      const dataSet = data.attributes.points.map((pair: number[]) => {
        return [timestampToTime(pair[0]), pair[1]];
      });

      run(dataSet);
    } else {
      run([[]]);
    }
  }, [data]);

  const run = (rawData: any) => {
    const chartDom = document.getElementById(uniqueId)!;
    const myChart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      dataset: [
        {
          id: "dataset_raw",
          source: rawData,
        },
        // {
        //   id: "this_token",
        //   fromDatasetId: "dataset_raw",
        //   transform: {
        //     type: "filter",
        //     config: {
        //       // and: [
        //       //   { dimension: "Year", gte: 1950 },
        //       //   { dimension: "Country", "=": "Germany" },
        //       // ],
        //     },
        //   },
        // },
        // {
        //   id: "dataset_since_1950_of_france",
        //   fromDatasetId: "dataset_raw",
        //   transform: {
        //     type: "filter",
        //     config: {
        //       and: [
        //         { dimension: "Year", gte: 1950 },
        //         { dimension: "Country", "=": "France" },
        //       ],
        //     },
        //   },
        // },
      ],
      // title: {
      //   text: "Token Price",
      // },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        // name: "Time",
        nameLocation: "middle",
      },
      yAxis: {
        name: "Price(USD)",
      },
      series: [
        {
          type: "line",
          datasetId: "dataset_raw",
          showSymbol: false,
          encode: {
            x: "Time",
            y: "Price",
            itemName: "Time",
            tooltip: ["Price"],
          },
        },
        // {
        //   type: "line",
        //   datasetId: "dataset_since_1950_of_france",
        //   showSymbol: false,
        //   encode: {
        //     x: "Year",
        //     y: "Income",
        //     itemName: "Year",
        //     tooltip: ["Income"],
        //   },
        // },
      ],
    };

    myChart.setOption<echarts.EChartsOption>(option);
  };

  return <div className='line-chart' id={uniqueId}></div>;
};

export { LineChart };

import { WalletProvider } from "@dataverse/wallet-provider";

import {
  ChainsListResponse,
  WalletFungiblePositionsResponse,
  WalletPortfolioResponse,
  WalletTransactionsResponse,
} from "./api";

type StateType = {
  walletProvider: WalletProvider;
  pendingCount: number;
  address?: string;
  chainsList?: ChainsListResponse;
  walletPortfolio?: WalletPortfolioResponse;
  walletTransactions?: WalletTransactionsResponse;
  walletFungiblePositions?: WalletFungiblePositionsResponse;
};

enum ActionType {
  SetAddress,
  IncreasePendingCount,
  DecreasePendingCount,
  SetChainsList,
  SetWalletPortfolio,
  SetWalletTransactions,
  SetWalletFungiblePositions,
}

type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<any>;
};

export { StateType, ActionType, ContextType };

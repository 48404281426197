enum PeriodParam {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  Max = "max",
}

enum ChainParam {
  Arbitrum = "arbitrum",
  Aurora = "aurora",
  Avalanche = "avalanche",
  Base = "base",
  BSC = "binance-smart-chain",
  Ethereum = "ethereum",
  Fantom = "fantom",
  Optimism = "optimism",
  Polygon = "polygon",
  Solana = "solana",
  Xdai = "xdai",
  ZksyncEra = "zksync-era",
}

enum CurrencyParam {
  ETH = "eth",
  BTC = "btc",
  USD = "usd",
  EUR = "eur",
  KRW = "krw",
  RUB = "rub",
  GBP = "gbp",
  AUD = "aud",
  CAD = "cad",
  INR = "inr",
  JPY = "jpy",
  NZD = "nzd",
  TRY = "try",
  ZAR = "zar",
  CNY = "cny",
  CHF = "chf",
}

type AssetsListResponse = AssetByIdResponse[];

type AssetByIdResponse = {
  type: string;
  id: string;
  links: {
    self: string;
  };
  attributes: {
    description: string;
    external_links: {
      type: string;
      name: string;
      url: string;
    }[];
    flags: {
      verified: boolean;
    };
    icon: {
      url: string;
    };
    implementations: {
      address: string;
      chain_id: string;
      decimals: number;
    }[];
    market_data: {
      changes: {
        percent_1d: number;
        percent_30d: number;
        percent_90d: number;
        percent_365d: number;
      };
      circulating_supply: number;
      fully_diluted_valuation: number;
      market_cap: number;
      price: number;
      total_supply: number;
    };
    name: string;
    symbol: string;
  };
  relationships: {
    chart_day: object;
    chart_hour: object;
    chart_max: object;
    chart_month: object;
    chart_week: object;
    chart_year: object;
  };
};

type AssetsChartResponse = {
  type: string;
  id: string;
  attributes: {
    begin_at: string;
    end_at: string;
    points: number[][];
    stats: {
      avg: number;
      first: number;
      last: number;
      max: number;
      min: number;
    };
  };
};

type GasPricesResponse = GasPriceResponse[];

type GasPriceResponse = {
  type: string;
  id: string;
  attributes: {
    gas_type: string;
    info: {
      base_fee?: number;
      fast:
        | {
            estimation_seconds: unknown;
            max_fee: number;
            priority_fee: number;
          }
        | number;
      slow:
        | {
            estimation_seconds: unknown;
            max_fee: number;
            priority_fee: number;
          }
        | number;
      standard:
        | {
            estimation_seconds: unknown;
            max_fee: number;
            priority_fee: number;
          }
        | number;
    };
    updated_at: string;
  };
  relationships: {
    chain: {
      type: string;
      id: string;
    };
    links: {
      related: string;
    };
  };
};

type ChainsListResponse = ChainResponse[];

type ChainResponse = {
  type: string;
  id: string;
  attributes: {
    explorer: {
      home_url: string;
      name: string;
      token_url_format: string;
      tx_url_format: string;
    };
    external_id: string;
    flags: {
      supports_bridge: boolean;
      supports_sending: boolean;
      supports_trading: boolean;
    };
    icon: {
      url: string;
    };
    name: string;
    rpc: {
      public_servers_url: string[];
    };
  };
  links: {
    self: string;
  };
  relationships: {
    native_fungible: {
      data: {
        id: string;
        type: string;
      };
      links: {
        related: string;
      };
    };
    wrapped_native_fungible: {
      data: {
        id: string;
        type: string;
      };
      links: {
        related: string;
      };
    };
  };
};

type WalletPortfolioResponse = {
  type: string;
  id: string;
  attributes: {
    changes: {
      absolute_1d: number | null;
      percent_1d: number | null;
    };
    positions_distribution_by_chain: object;
    positions_distribution_by_type: object;
    total: {
      positions: number;
    };
  };
};

type WalletTransactionsResponse = {
  type: string;
  id: string;
  attributes: {
    approvals: unknown[];
    fee: {
      fungible_info: {
        flags: {
          verified: boolean;
        };
        icon: {
          url: string;
        };
        implementations: {
          address: string;
          chain_id: string;
          decimals: number;
        }[];
        name: string;
        symbol: string;
      };
      price: unknown;
      quantity: {
        decimals: number;
        float: number;
        int: string;
        numeric: string;
      };
      value: unknown;
    };
    hash: string;
    mined_at: string;
    mined_at_block: number;
    nonce: number;
    operation_type: string;
    sent_from: string;
    sent_to: string;
    status: string;
    transfers: {
      direction: string;
      fungible_info?: {
        flags: {
          verified: boolean;
        };
        icon: {
          url: string;
        };
        implementations: {
          address: string;
          chain_id: string;
          decimals: number;
        }[];
        name: string;
        symbol: string;
      };
      nft_info: {
        contract_address: string;
        token_id: string;
        name: string;
        interface: string;
        content: object;
      };
      price: unknown;
      quantity: {
        int: string;
        decimals: number;
        float: number;
        numeric: string;
      };
      recipient: string;
      sender: string;
      value: unknown;
    }[];
  };
  relationships: {
    chain: {
      data: {
        type: string;
        id: string;
      };
      links: {
        related: string;
      };
    };
  };
}[];

type WalletFungiblePositionsResponse = {
  type: string;
  id: string;
  attributes: {
    changes: {
      absolute_1d: number;
      percent_1d: number;
    };
    flags: {
      displayable: boolean;
      is_trash: boolean;
    };
    fungible_info: {
      flags: {
        verified: boolean;
      };
      icon: {
        url: string | null;
      };
      implementations: {
        address: string;
        chain_id: string;
        decimals: number;
      }[];
      name: string;
      symbol: string;
    };
    name: string;
    parent: unknown;
    position_type: string;
    price: number;
    protocol: unknown;
    quantity: {
      decimals: number;
      float: number;
      int: string;
      numeric: string;
    };
    updated_at: string;
    updated_at_block: number;
    value: number;
  };
  relationships: {
    chain: {
      links: {
        related: string;
      };
      data: {
        id: string;
        type: string;
      };
    };
    fungible: {
      links: {
        related: string;
      };
      data: {
        id: string;
        type: string;
      };
    };
  };
}[];

export {
  PeriodParam,
  ChainParam,
  CurrencyParam,
  AssetByIdResponse,
  AssetsListResponse,
  GasPriceResponse,
  GasPricesResponse,
  ChainResponse,
  ChainsListResponse,
  AssetsChartResponse,
  WalletPortfolioResponse,
  WalletTransactionsResponse,
  WalletFungiblePositionsResponse,
};

import React from "react";
import "./index.scss";

import i18n from "@/i18n";
import { ChainResponse } from "@/types";
import { abbreviateAddress } from "@/utils";

interface IProps {
  token: string;
  data?: ChainResponse;
}

const Explorer = ({ token, data }: IProps) => {
  return (
    <div className='explorer'>
      <div className='chain-logo'>
        <img src={data?.attributes.icon.url} alt={data?.id} />
      </div>
      <div className='chain-text'>
        <div className='chain-text-name'>{data?.attributes.name}</div>
        <div className='chain-text-address'>
          <span>
            🖇️
            <a
              href={
                token
                  ? data?.attributes.explorer.token_url_format.replace(
                      "{ADDRESS}",
                      token,
                    )
                  : data?.attributes.explorer.home_url
              }
              target='_blank'
              rel='noreferrer'
            >
              {token
                ? abbreviateAddress(token)
                : i18n.t("components.explorer.unmatched_token")}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export { Explorer };

import { ChainParam, ChainResponse, ChainsListResponse } from "@/types";
import { http } from "@/utils";

const getChainsList = async () => {
  const response: ChainsListResponse = await http.get("chains/list/");
  return response;
};

const getChain = async (chain: ChainParam) => {
  const response: ChainResponse = await http.request({
    url: "chains/",
    method: "get",
    params: {
      chain,
    },
  });
  return response;
};

export { getChainsList, getChain };

import {
  AssetByIdResponse,
  AssetsChartResponse,
  AssetsListResponse,
  ChainParam,
  CurrencyParam,
  PeriodParam,
} from "@/types";
import { http } from "@/utils";

const getAssetsList = async ({
  size,
  currency,
  chain,
}: {
  size?: number;
  currency?: CurrencyParam;
  chain?: ChainParam;
} = {}) => {
  const response: AssetsListResponse = await http.request({
    url: "fungibles/list/",
    method: "get",
    params: {
      size,
      currency,
      chain,
    },
  });
  return response;
};

const getAssetById = async ({
  fungibleId,
  currency = CurrencyParam.USD,
}: {
  fungibleId: string;
  currency?: CurrencyParam;
}) => {
  const response: AssetByIdResponse = await http.request({
    url: "fungibles/",
    method: "get",
    params: {
      fungibleId,
      currency,
    },
  });
  return response;
};

const getAssetsChart = async ({
  fungibleId,
  chartPeriod,
  currency = CurrencyParam.USD,
}: {
  fungibleId: string;
  chartPeriod: PeriodParam;
  currency?: CurrencyParam;
}) => {
  const response: AssetsChartResponse = await http.request({
    url: "fungibles/chart/",
    method: "get",
    params: {
      fungibleId,
      chartPeriod,
      currency,
    },
  });
  return response;
};

export { getAssetsList, getAssetById, getAssetsChart };
